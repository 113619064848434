

import React from "react";
import { useIntl } from 'react-intl'

const Footer: React.FC = () => {
  const currentYear = new Date().getFullYear();
  const intl = useIntl()

  return (
    <>
      <footer className="footer-area bg-color">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-sm-6">
              <div className="single-footer-widget">
                <a href="/" className="logo">
                  <img
                    src="/img/logo.png"
                    alt="logo"
                    width={130}
                    height={54}
                  />
                </a>
                <p>
                  {intl.formatMessage({ id: "FooterText" })}
                </p>

                <ul className="social-link">
                  <li>
                    <a
                      className="d-block"
                      target="_blank"
                      href="https://www.facebook.com/share/TKb6yVZ9G9Mkedy6/?mibextid=qi2Omg"
                    >
                      <i className="bx bxl-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      className="d-block"
                      target="_blank"
                      href="https://www.instagram.com/oprazitr?utm_source=qr&igsh=MTBpMWRxbnVwczFhag=="
                    >
                      <i className="bx bxl-instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      className="d-block"
                      target="_blank"
                      href="https://www.linkedin.com/company/oprazi/"
                    >
                      <i className="bx bxl-linkedin"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-4 col-sm-6">
              <div className="single-footer-widget pl-5">
                <h3>{intl.formatMessage({ id: "OpraziOPE" })}</h3>

                <ul className="footer-links-list">
                  <li>
                    <a href="/">{intl.formatMessage({ id: "Homepage" })}</a>
                  </li>
                  <li>
                    <a href="/courses">{intl.formatMessage({ id: "Course" })}</a>
                  </li>
                  <li>
                    <a href="/exams">{intl.formatMessage({ id: "Exam" })}</a>
                  </li>
                  <li>
                    <a href="/demo/courses">{intl.formatMessage({ id: "Demo" })} {intl.formatMessage({ id: "Course" })} </a>
                  </li>
                  <li>
                    <a href="/demo/exams">{intl.formatMessage({ id: "Demo" })} {intl.formatMessage({ id: "Exam" })} </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-4 col-sm-6">
              <div className="single-footer-widget">
                <h3>{intl.formatMessage({ id: "Address" })}</h3>

                <ul className="footer-contact-info">
                  <li>
                    <i className="bx bx-map"></i>
                    {intl.formatMessage({ id: "Company" })}
                    <br /> {intl.formatMessage({ id: "AddressLine1" })}
                    <br /> {intl.formatMessage({ id: "AddressLine2" })}
                  </li>
                  <li>
                    <i className="bx bx-phone-call"></i>
                    <a href={"tel:" + intl.formatMessage({ id: "PhoneFormatted" })}>{intl.formatMessage({ id: "Phone" })}</a>
                  </li>
                  <li>
                    <i className="bx bx-envelope"></i>
                    <a href={"mailto:" + intl.formatMessage({ id: "OpraziOpeEmail" })}>{intl.formatMessage({ id: "OpraziOpeEmail" })}</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="footer-bottom-area">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-6">
                <p>
                  &copy;{currentYear} {intl.formatMessage({ id: "AllRights" })} <strong><a target="_blank" href="https://oprazi.com.tr/">{intl.formatMessage({ id: "Oprazi" })}</a></strong>{intl.formatMessage({ id: "BelongTo" })}
                </p>
              </div>

              <div className="col-lg-6 col-md-6">
                <ul>
                  <li>
                    <a target="_blank" href={intl.formatMessage({ id: "PrivacyLink" })} >{intl.formatMessage({ id: "Privacy" })}</a>
                  </li>
                  <li>
                    <a target="_blank" href={intl.formatMessage({ id: "PDPLLink" })}>{intl.formatMessage({ id: "PDPL" })}</a>
                  </li>
                  <li>
                    <a target="_blank" href={intl.formatMessage({ id: "DeliveryAndReturnLink" })}>{intl.formatMessage({ id: "DeliveryAndReturn" })}</a>
                  </li>
                  <li>
                    <a target="_blank" href={intl.formatMessage({ id: "ContractLink" })}>{intl.formatMessage({ id: "Contract" })}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="footer-map">
          <img
            src="/img/footer-map.png"
            alt="image"
            width={693}
            height={362}
          />
        </div>
      </footer>
    </>
  );
};

export default Footer;
