import React, {useState, useEffect} from 'react';
import logo from './logo.svg';
import Home from "./app/page";
import Courses from "./app/courses/page";
import CourseDetail from "./app/courses/details/page";
import Exams from "./app/exams/page";
import ExamDetail from "./app/exams/details/page";
import EventDetail from "./app/events/details/page";
import DemoCourses from "./app/demo-courses/page";
import DemoExams from "./app/demo-exams/page";
import CourseCompleted from "./app/coming-soon/page";
import Panel from "./app/panel/page";
import CourseSchedule from "./app/course-schedule/page";
import ExamSchedule from "./app/exam-schedule/page";
import Login from "./app/profile-authentication/page";
import Logout from "./app/profile-authentication/logout";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import {setLanguage, useLang} from './i18n/i18nConfig'

var userLanguage = window.navigator.language;

function App() {
  const lang = useLang()

  useEffect(() => {
    console.log("userLanguage " + userLanguage)
    console.log("lang " + lang)
    if(userLanguage.length > 2)
        userLanguage = userLanguage.substring(0, 2)
    if((lang === null || lang === undefined) && lang !== userLanguage)
      setLanguage(userLanguage)
  }, [])

  return (
      <BrowserRouter>
        <Routes>
          <Route path="/">
            <Route index element={<Home />} />
            <Route path="/courses" element={<Courses />} />
            <Route path="/course/details/:courseId" element={<CourseDetail />} />
            <Route path="/exams" element={<Exams />} />
            <Route path="/exam/details/:courseId" element={<ExamDetail />} />
            <Route path="/course/details" element={<CourseDetail />} />
            <Route path="/event/:courseId" element={<EventDetail /> }/>
            <Route path="/demo/courses" element={<DemoCourses />} />
            <Route path="/demo/exams" element={<DemoExams />} />
            <Route path="/course-completed" element={<CourseCompleted />} />
            <Route path="/panel" element={<Panel />} />
            <Route path="/course-schedule" element={<CourseSchedule />} />
            <Route path="/exam-schedule" element={<ExamSchedule />} />
            <Route path="/login" element={<Login />} />
            <Route path="/logout" element={<Logout />} />
          </Route>
        </Routes>
      </BrowserRouter>
  );
}

export default App;
