import React from "react";
import Navbar from "../../components/Layouts/Navbar";
import PageBanner from "../../components/Common/PageBanner";
import LoginForm from "../../components/ProfileAuthentication/LoginForm";
import RegisterForm from "../../components/ProfileAuthentication/RegisterForm";
import Footer from "../../components/Layouts/Footer";
import { useIntl } from 'react-intl'

export default function Page() {
  const intl = useIntl()
  return (
    <>
      <Navbar />

      <PageBanner
        pageTitle={intl.formatMessage({ id: "UserLogin" })}
        homePageText={intl.formatMessage({ id: "Homepage" })}
        homePageUrl="/"
        activePageText={intl.formatMessage({ id: "UserLogin" })}
      />

      <section className="profile-authentication-area ptb-10">
      <div className="courses-details-image">
          <img
            src="/img/courses/courses-details-2.jpg"
            alt="image"
            width={1920}
            height={152}
          />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12">
              <LoginForm />
            </div>

          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
