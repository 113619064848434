

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import MenuItem from "./MenuItem";
import { mainmenus } from "../libs/mainmenus";
import { menus } from "../libs/menus";
import { usermenus } from "../libs/usermenus";
import SearchForm from "./SearchForm";
import { useAuth } from "../../core/auth/Auth"
import { getUserByToken } from "../../core/auth/_requests"
import { useIntl } from 'react-intl'
import {setLanguage} from '../../i18n/i18nConfig'

const Navbar: React.FC = () => {
  const intl = useIntl()
  const [menu, setMenu] = useState(true);
  const { auth, saveCurrentUser } = useAuth()
  const navigate = useNavigate();
  const toggleNavbar = () => {
    setMenu(!menu);
  };

  useEffect(() => {
    if (auth)
      checkUser()

    let elementId = document.getElementById("navbar");
    document.addEventListener("scroll", () => {
      if (window.scrollY > 170) {
        elementId?.classList.add("is-sticky");
      } else {
        elementId?.classList.remove("is-sticky");
      }
    });
  });

  const checkUser = async () => {
    try {
      await getUserByToken(auth?.api_token!, auth?.currentRole).then((user) => {
        saveCurrentUser(user)
      })
    } catch (error) {
      console.error(error)
      navigate("/logout");
    }
  }

  const classOne = menu
    ? "collapse navbar-collapse mean-menu"
    : "collapse navbar-collapse show";
  const classTwo = menu
    ? "navbar-toggler navbar-toggler-right collapsed"
    : "navbar-toggler navbar-toggler-right";

  return (
    <>
      <div id="navbar" className="navbar-area">
        <div className="tarn-nav">
          <div className="container-fluid">
            <nav className="navbar navbar-expand-lg navbar-light">
              <a href="/" className="navbar-brand">
                <img src="/img/logo.png" alt="logo" width={280} height={52} />
              </a>

              <button
                onClick={toggleNavbar}
                className={classTwo}
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="icon-bar top-bar"></span>
                <span className="icon-bar middle-bar"></span>
                <span className="icon-bar bottom-bar"></span>
              </button>

              <div className={classOne} id="navbarSupportedContent">
                <ul className="navbar-nav">
                  <li className="nav-item" key={"Homepage"}>
                    <a
                      href="/"
                      className="nav-link"
                    >
                      {intl.formatMessage({ id: "Homepage" })}
                    </a>
                  </li>
                  <li className="nav-item" key={"Exam"}>
                    <a
                      href="/exams"
                      className="nav-link"
                    >
                      {intl.formatMessage({ id: "Exam" })}
                    </a>
                  </li>
                  <li className="nav-item" key={"Demo"}>
                    <a
                      href={"#"}
                      className="nav-link"
                      onClick={(e) => e.preventDefault()}
                    >
                      {intl.formatMessage({ id: "Demo" })} <i className="bx bx-chevron-down"></i>
                    </a>

                    <ul className="dropdown-menu">
                      <li className="nav-item" key={"DemoCourse"}>
                        <a
                          href="/demo/courses"
                          className={`nav-link `}
                        >
                          {intl.formatMessage({ id: "Course" })}
                        </a>
                      </li>
                      <li className="nav-item" key={"DemoExam"}>
                        <a
                          href="/demo/exams"
                          className={`nav-link `}
                        >
                          {intl.formatMessage({ id: "Exam" })}
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className="nav-item" key={"Language"}>
                    <a
                      href={"#"}
                      className="nav-link"
                      onClick={(e) => e.preventDefault()}
                    >
                      {intl.formatMessage({ id: "Language" })} <i className="bx bx-chevron-down"></i>
                    </a>

                    <ul className="dropdown-menu">
                      <li className="nav-item" key={"English"}>
                        <a
                          href="#"
                          className={`nav-link `}
                          onClick={(e) => {
                              e.preventDefault()
                              setLanguage("en")
                          }}  
                        >
                          {intl.formatMessage({ id: "English" })}
                        </a>
                      </li>
                      <li className="nav-item" key={"Turkish"}>
                        <a
                          href="#"
                          className={`nav-link `}
                          onClick={(e) => {
                            e.preventDefault()
                            setLanguage("tr")
                        }} 
                        >
                          {intl.formatMessage({ id: "Turkish" })}
                        </a>
                      </li>
                      <li className="nav-item" key={"Russian"}>
                        <a
                          href="#"
                          className={`nav-link `}
                          onClick={(e) => {
                            e.preventDefault()
                            setLanguage("ru")
                        }} 
                        >
                          {intl.formatMessage({ id: "Russian" })}
                        </a>
                      </li>
                      <li className="nav-item" key={"Arabic"}>
                        <a
                          href="#"
                          className={`nav-link `}
                          onClick={(e) => {
                            e.preventDefault()
                            setLanguage("ar")
                        }} 
                        >
                          {intl.formatMessage({ id: "Arabic" })}
                        </a>
                      </li>
                      <li className="nav-item" key={"Deutsch"}>
                        <a
                          href="#"
                          className={`nav-link `}
                          onClick={(e) => {
                            e.preventDefault()
                            setLanguage("de")
                        }} 
                        >
                          {intl.formatMessage({ id: "Deutsch" })}
                        </a>
                      </li>
                      <li className="nav-item" key={"French"}>
                        <a
                          href="#"
                          className={`nav-link `}
                          onClick={(e) => {
                            e.preventDefault()
                            setLanguage("fr")
                        }} 
                        >
                          {intl.formatMessage({ id: "French" })}
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>

                <div className="others-option d-flex align-items-center">
                  <div className="option-item">
                    {/* SearchForm */}
                    <SearchForm />
                  </div>
                  {auth &&
                    <ul className="navbar-nav">
                      <li className="nav-item" key={"MyAccount"}>
                        <a
                          href={"#"}
                          className="nav-link"
                          onClick={(e) => e.preventDefault()}
                        >
                          {intl.formatMessage({ id: "MyAccount" })} <i className="bx bx-chevron-down"></i>
                        </a>

                        <ul className="dropdown-menu">
                          <li className="nav-item" key={"Panel"}>
                            <a
                              href="/panel"
                              className={`nav-link `}
                            >
                              {intl.formatMessage({ id: "Panel" })}
                            </a>
                          </li>
                          <li className="nav-item" key={"CourseSchedule"}>
                            <a
                              href="/course-schedule"
                              className={`nav-link `}
                            >
                              {intl.formatMessage({ id: "CourseSchedule" })}
                            </a>
                          </li>
                          <li className="nav-item" key={"ExamSchedule"}>
                            <a
                              href="/exam-schedule"
                              className={`nav-link `}
                            >
                              {intl.formatMessage({ id: "ExamSchedule" })}
                            </a>
                          </li>
                          <li className="nav-item" key={"SecureLogout"}>
                            <a
                              href="/logout"
                              className={`nav-link `}
                            >
                              {intl.formatMessage({ id: "SecureLogout" })}
                            </a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  }
                  {!auth &&
                    <div className="option-item">
                      <a href="/login" className="default-btn">
                        <i className="flaticon-right"></i> {intl.formatMessage({ id: "Login" })}{" "}
                        <span></span>
                      </a>
                    </div>
                  }
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
