

import React from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper/modules";
import { useIntl } from 'react-intl'

const CaseStudy: React.FC = () => {
  const intl = useIntl()
  return (
    <>
      <div className="case-study-area bg-fffbf5">
        <Swiper
          navigation={true}
          autoplay={{
            delay: 5000,
            disableOnInteraction: true,
            pauseOnMouseEnter: true,
          }}
          modules={[Navigation, Autoplay]}
          className="case-study-slides"
        >
          <SwiperSlide>
            <div className="single-case-study-item ptb-100">
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-lg-6 col-md-12">
                    <div className="case-study-content">
                      <span className="sub-title">

                      </span>
                      <h2>{intl.formatMessage({ id: "Slider1Title" })}</h2>
                      <p>
                        {intl.formatMessage({ id: "Slider1Content" })}
                      </p>

                      <a
                        href="/demo/exams"
                        className="default-btn"
                      >
                        <i className="flaticon-view"></i>
                        {intl.formatMessage({ id: "StartWithDemo" })}
                        <span></span>
                      </a>
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-12">
                    <div className="case-study-image">
                      <a href="/case-studies/details" className="d-block">
                        <img
                          src="/img/case-study/case-study1.jpg"
                          alt="image"
                          width={534}
                          height={534}
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="single-case-study-item ptb-100">
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-lg-6 col-md-12">
                    <div className="case-study-content">
                      <span className="sub-title">

                      </span>
                      <h2>{intl.formatMessage({ id: "Slider2Title" })}</h2>
                      <p>
                        {intl.formatMessage({ id: "Slider2Content" })}
                      </p>

                      <a
                        href="/demo/exams"
                        className="default-btn"
                      >
                        <i className="flaticon-view"></i>
                        {intl.formatMessage({ id: "StartWithDemo" })}
                        <span></span>
                      </a>
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-12">
                    <div className="case-study-image">
                      <a href="/case-studies/details" className="d-block">
                        <img
                          src="/img/case-study/case-study2.jpg"
                          alt="image"
                          width={534}
                          height={534}
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </>
  );
};

export default CaseStudy;
