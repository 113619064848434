

import React from "react";
import { useIntl } from 'react-intl'

const OurMission: React.FC = () => {
  const intl = useIntl()
  return (
    <>
      <div className="our-mission-area ptb-100">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12">
              <div className="our-mission-content">
                <div className="content">
                  <h2>
                    {intl.formatMessage({ id: "OurMissionTitle" })}
                  </h2>

                  <p>
                    {intl.formatMessage({ id: "OurMissionContent1" })}
                  </p>

                  <ul className="features-list">
                    <li>
                      <i className="flaticon-tick"></i> {intl.formatMessage({ id: "OurMissionContent2" })}
                    </li>
                    <li>
                      <i className="flaticon-tick"></i> {intl.formatMessage({ id: "OurMissionContent3" })}
                    </li>
                    <li>
                      <i className="flaticon-tick"></i> {intl.formatMessage({ id: "OurMissionContent4" })}
                    </li>
                    <li>
                      <i className="flaticon-tick"></i> {intl.formatMessage({ id: "OurMissionContent5" })}
                    </li>
                    <li>
                      <i className="flaticon-tick"></i> {intl.formatMessage({ id: "OurMissionContent6" })}
                    </li>
                  </ul>
                  <p>
                    {intl.formatMessage({ id: "OurMissionContent7" })}
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-12">
              <div className="our-mission-image">
                <img
                  src="/img/our-mission/our-mission2.png"
                  alt="image"
                  width={778}
                  height={430}
                />
                <div className="shape">
                  <img
                    src="/img/our-mission/our-mission-shape1.png"
                    alt="image"
                    width={919}
                    height={875}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OurMission;
