import React, { useEffect, useState } from "react";
import Navbar from "../../../components/Layouts/Navbar";
import PageBanner from "../../../components/Common/PageBanner";
import CourseDetailsContent from "../../../components/CourseDetails/CourseDetailsContent";
import RelatedCourses from "../../../components/CourseDetails/RelatedCourses";
import Footer from "../../../components/Layouts/Footer";
import { getCourseById } from '../../../core/course/_requests'
import { Course } from '../../../core/course/_models'
import { useAuth } from "../../../core/auth/Auth"
import { useParams } from 'react-router';
import { useIntl } from 'react-intl'

export default function Page() {
  const intl = useIntl()
  const { courseId } = useParams();
  const { currentUser } = useAuth()
  const [course, setCourse] = useState<Course>()
  useEffect(() => {
    getCourseById(Number(courseId)).then((value) => {
      if (value)
        setCourse(value)
    })
  }, [])
  return (
    <>
      <Navbar />

      {course &&
        <PageBanner
          pageTitle={intl.formatMessage({ id: "CourseDetails" })}
          homePageText={intl.formatMessage({ id: "Homepage" })}
          homePageUrl="/"
          activePageText={String(course?.name)}
        />
      }

      {course && <CourseDetailsContent course={course} />}


      <Footer />
    </>
  );
}
