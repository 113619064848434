import React from "react";
import Navbar from "../components/Layouts/Navbar";
import MainBanner from "../components/DataAnalyticsMLConsulting/MainBanner";
import Partner from "../components/DataScienceOnlineCourses/Partner";
import FeaturedServices from "../components/DataScienceOnlineCourses/FeaturedServices";
import AboutUs from "../components/DataScienceOnlineCourses/AboutUs";
import OurMission from "../components/DataScienceOnlineCourses/OurMission";
import Funfacts from "../components/BigDataAnalysisStartup/Funfacts";
import Courses from "../components/DataScienceOnlineCourses/Courses";
import ExploreLearning from "../components/DataScienceOnlineCourses/ExploreLearning";
import UpcomingBootcamps from "../components/DataScienceOnlineCourses/UpcomingBootcamps";
import Testimonials from "../components/Common/Testimonials";
import BlogPost from "../components/DataScienceOnlineCourses/BlogPost";
import Subscribe from "../components/DataScienceOnlineCourses/Subscribe";
import Footer from "../components/Layouts/Footer";
import CaseStudy from "../components/BigDataAnalysisStartup/CaseStudy";
import StartProject from "../components/Common/StartProject";
import WeServe from "../components/BigDataAnalysisStartup/WeServe";

export default function Home() {
  return (
    <>
      <Navbar />

      <CaseStudy />

      <AboutUs />

      <Testimonials />

      <OurMission />

      <StartProject />

      <Footer />
    </>
  );
}
