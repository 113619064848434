import axios, {AxiosResponse} from 'axios'
import {AuthModel, UserModel, H5PUserModel} from './_models'
import {ID, Response} from '../base/models'
const API_URL = process.env.REACT_APP_API_URL
const H5P_API_URL = process.env.REACT_APP_H5P_API_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/user/verifytoken`
export const REFRESH_ACCESSTOKEN_URL = `${API_URL}/user/refreshtoken`
export const LOGIN_URL = `${API_URL}/user/login`
export const LOGIN_H5P_URL = `${H5P_API_URL}/login`
export const LOGOUT_URL = `${API_URL}/user/logout`
export const LOGOUT_H5P_URL = `${H5P_API_URL}/logout`
export const REGISTER_URL = `${API_URL}/user/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/user/forgotpassword`
export const RESET_PASSWORD_URL = `${API_URL}/user/resetpassword`

// Server should return AuthModel
const login = (email: string, password: string, role: string): Promise<AuthModel | undefined> => {
  return axios.post(LOGIN_URL, {
    email : email,
    password : password,
    current_role : role
  })
  .then((response: AxiosResponse<Response<AuthModel>>) => response.data)
  .then((response: Response<AuthModel>) => response.data)
}

const loginToH5P = (api_token: string, current_role: string): Promise<H5PUserModel | undefined> => {
  var instance = axios.create();
  delete instance.defaults.headers.common['Authorization'];
  //instance.defaults.headers.common['Accept'] = 'application/json, text/plain, */*'
  return instance.post(LOGIN_H5P_URL, {
    username : api_token,
    password : current_role
  },
  {
    headers: {
      Accept: 'application/json, text/plain, */*'
    },
    withCredentials: true
  })
  .then((response: Response<H5PUserModel>) => response.data)
}

const logout = (): Promise<void> => {
  return axios.post<{result: boolean}>(LOGOUT_URL).then(() => {})
}

const logoutFromH5P = (csrfToken: string): Promise<void> => {
  return axios.post(LOGOUT_H5P_URL,
    {

    },
    {
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-type': 'application/json',
        'CSRF-Token': csrfToken ?? ''
        
      }
    }
  ).then(() => {})
}

// Server should return AuthModel
const register = (
  email: string,
  firstName: string,
  lastName: string,
  password: string,
  password_confirmation: string,
  institutionName: string,
  phone: string,
  cityId: number,
  languageId: number,
  subinstitutionTypeId: number
) : Promise<AuthModel | undefined> => {
  return axios.post(REGISTER_URL, {
    email,
    firstName,
    lastName,
    password,
    password_confirmation,
    institutionName,
    phone,
    cityId,
    languageId,
    subinstitutionTypeId
  })
  .then((response: AxiosResponse<Response<AuthModel>>) => response.data)
  .then((response: Response<AuthModel>) => response.data)
}

// Server should return object => { result: boolean } (Is Email in DB)
const requestPassword = (email: string): Promise<void> => {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
    email,
  }).then(() => {})
}

const resetPassword = (email: string, token : string, password : string, changepassword : string) : Promise<void> =>{
  return axios.post<{result: boolean}>(RESET_PASSWORD_URL, {
    email,
    token,
    password,
    changepassword
  }).then(() => {})
}

const getUserByToken = (token: string, role: string | undefined) : Promise<UserModel | undefined> => {
  return axios.post(GET_USER_BY_ACCESSTOKEN_URL, {
    api_token: token,
    current_role: role
  })
  .then((response: AxiosResponse<Response<UserModel>>) => response.data)
  .then((response: Response<UserModel>) => response.data)
}

const refreshToken = (token: string, role: string | undefined) : Promise<AuthModel | undefined> => {
  return axios.post(REFRESH_ACCESSTOKEN_URL, {
    api_token: token,
    current_role: role
  })
  .then((response: AxiosResponse<Response<AuthModel>>) => response.data)
  .then((response: Response<AuthModel>) => response.data)
}

export {login, loginToH5P, logout, logoutFromH5P, register, requestPassword, resetPassword, getUserByToken, refreshToken}
