import React, { useEffect, useState } from "react";
import { getCourseSchedules } from '../../core/courseschedule/_requests'
import { CourseSchedule } from '../../core/courseschedule/_models'
import { useAuth } from "../../core/auth/Auth"
import Navbar from "../../components/Layouts/Navbar";
import PageBanner from "../../components/Common/PageBanner";
import StartProjectTwo from "../../components/Common/StartProjectTwo";
import OurHistoryContentOne from "../../components/CourseSchedule/OurHistoryContentOne";
import Footer from "../../components/Layouts/Footer";
import { useIntl } from 'react-intl'

export default function Page() {
  const intl = useIntl()
  const [courseSchedules, setCourseSchedules] = useState<CourseSchedule[]>()
  const [isLoading, setIsLoading] = useState(true)
  const { currentUser } = useAuth()
  useEffect(() => {
    getCourseSchedules('&unitId=' + currentUser?.unitId + '&customerId=' + currentUser?.customerId + '&isTest=0').then((values) => {
      setCourseSchedules(values.data)
      setIsLoading(false)
    })
  }, [])
  return (
    <>
      <Navbar />

      <PageBanner
        pageTitle={intl.formatMessage({ id: "CourseSchedule" })}
        homePageText={intl.formatMessage({ id: "Homepage" })}
        homePageUrl="/"
        activePageText={intl.formatMessage({ id: "CourseSchedule" })}
      />

      {courseSchedules && <OurHistoryContentOne courseSchedules={courseSchedules}/>}

      {!isLoading && (!courseSchedules || courseSchedules.length === 0) && <StartProjectTwo />}

      <Footer />
    </>
  );
}
