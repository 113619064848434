import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../base/models'
import {CourseSchedule, CourseSchedulesQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_API_URL
const GET_COURSESCHEDULE_URL = `${API_URL}/courseschedule/getcourseschedule`
const CREATE_COURSESCHEDULE_URL = `${API_URL}/courseschedule/addcourseschedule`
const UPDATE_COURSESCHEDULE_URL = `${API_URL}/courseschedule/updatecourseschedule`
const DELETE_COURSESCHEDULE_URL = `${API_URL}/courseschedule/deletecourseschedule`
const GET_COURSESCHEDULES_URL = `${API_URL}/courseschedule/getcourseschedules`

const getCourseSchedules = (query: string): Promise<CourseSchedulesQueryResponse> => {
  return axios
    .get(`${GET_COURSESCHEDULES_URL}?${query}`)
    .then((d: AxiosResponse<CourseSchedulesQueryResponse>) => d.data)
}

const getCourseScheduleById = (id: ID): Promise<CourseSchedule | undefined> => {
  return axios
    .get(`${GET_COURSESCHEDULE_URL}/${id}`)
    .then((response: AxiosResponse<Response<CourseSchedule>>) => response.data)
    .then((response: Response<CourseSchedule>) => response.data)
}

const createCourseSchedule = (courseschedule: CourseSchedule): Promise<CourseSchedule | undefined> => {
  return axios
    .post(CREATE_COURSESCHEDULE_URL, courseschedule)
    .then((response: AxiosResponse<Response<CourseSchedule>>) => response.data)
    .then((response: Response<CourseSchedule>) => response.data)
}

const updateCourseSchedule = (courseschedule: CourseSchedule): Promise<CourseSchedule | undefined> => {
  return axios
    .put(`${UPDATE_COURSESCHEDULE_URL}/${courseschedule.id}`, courseschedule)
    .then((response: AxiosResponse<Response<CourseSchedule>>) => response.data)
    .then((response: Response<CourseSchedule>) => response.data)
}

const deleteCourseSchedule = (coursescheduleId: ID): Promise<void> => {
  return axios.delete(`${DELETE_COURSESCHEDULE_URL}/${coursescheduleId}`).then(() => {})
}

const deleteSelectedCourseSchedules = (coursescheduleIds: Array<ID>): Promise<void> => {
  const requests = coursescheduleIds.map((id) => axios.delete(`${DELETE_COURSESCHEDULE_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

export {getCourseSchedules, deleteCourseSchedule, deleteSelectedCourseSchedules, getCourseScheduleById, createCourseSchedule, updateCourseSchedule}
