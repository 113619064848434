

import React from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper/modules";
import { useIntl } from 'react-intl'

const Testimonials: React.FC = () => {
  const intl = useIntl()
  return (
    <>
      <div className="testimonials-area bg-23173a ptb-100">
        <div className="container">
          <div className="section-title">
            <h2>{intl.formatMessage({ id: "TestimonialsTitle" })}</h2>
            <p>
              {intl.formatMessage({ id: "TestimonialsContent1" })}
            </p>
          </div>

          <Swiper
            navigation={true}
            spaceBetween={30}
            autoplay={{
              delay: 5000,
              disableOnInteraction: true,
              pauseOnMouseEnter: true,
            }}
            breakpoints={{
              0: {
                slidesPerView: 1,
              },
              768: {
                slidesPerView: 2,
              },
            }}
            modules={[Navigation, Autoplay]}
            className="testimonials-slides"
          >
            <SwiperSlide>
              <div className="single-testimonials-box">
                <p>
                  <img
                    src="/img/ope-exam-tablet-1.png"
                    alt="image"
                    width={512}
                    height={512}
                  />
                </p>

                <div className="client-info">
                  <div className="title">
                    <h3>{intl.formatMessage({ id: "TestimonialsContent2" })}</h3>
                    <span>{intl.formatMessage({ id: "TestimonialsContent3" })}</span>
                  </div>
                </div>
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="single-testimonials-box">
                <p>
                  <img
                    src="/img/ope-exam-tablet-2.png"
                    alt="image"
                    width={512}
                    height={512}
                  />
                </p>

                <div className="client-info">
                  <div className="title">
                    <h3>{intl.formatMessage({ id: "TestimonialsContent4" })}</h3>
                    <span>{intl.formatMessage({ id: "TestimonialsContent5" })}</span>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>

        </div>

        <div className="vector-shape9">
          <img
            src="/img/shape/vector-shape9.png"
            alt="image"
            width={399}
            height={264}
          />
        </div>
        <div className="vector-shape10">
          <img
            src="/img/shape/vector-shape10.png"
            alt="image"
            width={316}
            height={285}
          />
        </div>
      </div>
    </>
  );
};

export default Testimonials;
