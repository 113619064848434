import React from "react";
import Navbar from "../../components/Layouts/Navbar";
import PageBanner from "../../components/Common/PageBanner";
import CoursesGridContent from "../../components/DemoExams/CoursesGridContent";
import Footer from "../../components/Layouts/Footer";
import { useIntl } from 'react-intl'

export default function Page() {
  const intl = useIntl()
  return (
    <>
      <Navbar />

      <PageBanner
        pageTitle={intl.formatMessage({ id: "Exam" }) + ' ' + intl.formatMessage({ id: "Demo" })}
        homePageText={intl.formatMessage({ id: "Homepage" })}
        homePageUrl="/"
        activePageText={intl.formatMessage({ id: "Exam" }) + ' ' + intl.formatMessage({ id: "Demo" })}
      />

      <CoursesGridContent />

      <Footer />
    </>
  );
}
