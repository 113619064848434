

import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import CoursesDetailsSidebar from "./CoursesDetailsSidebar";
import { Course } from '../../core/course/_models'
import { useIntl } from 'react-intl'

interface IProps {
  course: Course
}
const CourseDetailsContent: React.FC<IProps> = ({ course }) => {
  const intl = useIntl()

  return (
    <>
      <div className="courses-details-area pb-100">
        <div className="courses-details-image">
          <img
            src="/img/courses/courses-details.jpg"
            alt="image"
            width={1920}
            height={650}
          />
        </div>

        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-12">
              <Tabs className="courses-details-desc">
                <TabList>
                  <Tab>{intl.formatMessage({ id: "Content" })}</Tab>
                  <Tab>{intl.formatMessage({ id: "SkillSet" })}</Tab>
                </TabList>

                <TabPanel>
                  <div className="courses-overview">
                    <h3>{course.name}</h3>
                    <p>
                      {course.description}
                    </p>
                  </div>
                </TabPanel>

                <TabPanel>
                  <div className="courses-curriculum">
                    {course.courseSkills && course.courseSkills.map((courseSkill) =>
                      <>
                        <h3 key={"title" + course.id}>{courseSkill.name}</h3>
                        <ul key={"ul" + course.id}>
                              <li>
                                <a
                                  href="#"
                                  className="d-flex justify-content-between align-items-center"
                                >
                                  <span className="courses-name">
                                  {course.courseSkillQuestions && course.courseSkillQuestions.filter(x => x.courseSkillId === courseSkill.id).length + ' ' + intl.formatMessage({ id: "Question" })}
                                  </span>
                                  <div className="courses-meta">
                                    <span className="duration">{course.courseSkillQuestions && course.courseSkillQuestions?.length > 0 && Math.round(course.duration / course.courseSkillQuestions?.length * course.courseSkillQuestions.filter(x => x.courseSkillId === courseSkill.id).length * 100) / 100 + ' ' + intl.formatMessage({ id: "Minute" })}</span>
                                  </div>
                                </a>
                              </li>
                        </ul>
                      </>
                    )}
                  </div>
                </TabPanel>

              </Tabs>
            </div>

            <div className="col-lg-4 col-md-12">
              {course && <CoursesDetailsSidebar course={course} />}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CourseDetailsContent;
