

import React, {FC, createContext, useContext} from 'react'
import { WithChildren } from '../base/react18MigrationHelpers'
import { isNotEmpty } from '../base/helpers'

const ROLE_CONFIG_KEY = process.env.REACT_APP_ROLE_CONFIG_KEY || 'OpeOpraziRoleConfig'

const initialState = {
  selectedRole: '',
}

function getConfig() {
  const role = localStorage.getItem(ROLE_CONFIG_KEY)
  
  if (isNotEmpty(role)) {
    try {
      return JSON.parse(String(role)).selectedRole
    } catch (er) {
      console.error(er)
    }
  }
  
  return ''
}

// Side effect
export function setRole(role: string) {
  localStorage.setItem(ROLE_CONFIG_KEY, JSON.stringify({selectedRole: role}))
}

const RoleContext = createContext(initialState)

const useRole = () => {
  return String(useContext(RoleContext))
}

const RolesProvider: FC<WithChildren> = ({children}) => {
  const selectedRole = getConfig()
  return <RoleContext.Provider value={selectedRole}>{children}</RoleContext.Provider>
}

export {RolesProvider, useRole}
