import React from "react";
import { useIntl } from 'react-intl'

const AboutUs: React.FC = () => {
  const intl = useIntl()
  return (
    <>
      <div className="about-area res-pt-0 pb-100 pt-70">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12">
              <div className="about-img">
                <img
                  src="/img/about/about-img8.png"
                  alt="image"
                  width={848}
                  height={490}
                />
                <div className="shape">
                  <img
                    src="/img/about/about-shape1.png"
                    alt="image"
                    width={924}
                    height={875}
                  />
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-12">
              <div className="about-content">
                <div className="content">
                  <h2>{intl.formatMessage({ id: "AboutUsTitle" })}</h2>
                  <p>
                    {intl.formatMessage({ id: "AboutUsContent1" })}
                  </p>
                  <ul className="content-list">
                    <li>
                      <i className="flaticon-tick"></i> {intl.formatMessage({ id: "AboutUsContent2" })}
                    </li>
                    <li>
                      <i className="flaticon-tick"></i> {intl.formatMessage({ id: "AboutUsContent3" })}
                    </li>
                  </ul>
                  <p>
                    {intl.formatMessage({ id: "AboutUsContent4" })}
                  </p>
                  <p>
                    {intl.formatMessage({ id: "AboutUsContent5" })}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUs;
