

import React from "react";
import { useIntl } from 'react-intl'

const StartProject: React.FC = () => {
  const intl = useIntl()
  return (
    <>
      <div className="project-start-area bg-color ptb-100">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12">
              <div className="project-start-image">
                <img
                  src="/img/project-start1.png"
                  alt="image"
                  width={600}
                  height={398}
                />
              </div>
            </div>

            <div className="col-lg-6 col-md-12">
              <div className="project-start-content">
                <h2>{intl.formatMessage({ id: "StartProjectTitle" })}</h2>
                <p>
                  {intl.formatMessage({ id: "StartProjectContent" })}
                </p>

                <a href="/login" className="default-btn">
                  <i className="flaticon-web"></i>
                  {intl.formatMessage({ id: "Register" })}
                  <span></span>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="vector-shape9">
          <img
            src="/img/shape/vector-shape9.png"
            alt="image"
            width={399}
            height={364}
          />
        </div>
        <div className="vector-shape10">
          <img
            src="/img/shape/vector-shape10.png"
            alt="image"
            width={316}
            height={285}
          />
        </div>
      </div>
    </>
  );
};

export default StartProject;
