import React, {useEffect} from "react";
import Navbar from "../../components/Layouts/Navbar";
import PageBanner from "../../components/Common/PageBanner";
import LoginForm from "../../components/ProfileAuthentication/LoginForm";
import RegisterForm from "../../components/ProfileAuthentication/RegisterForm";
import Footer from "../../components/Layouts/Footer";
import {useAuth} from '../../core/auth/Auth'
import { useIntl } from 'react-intl'

export default function Page() {
  const intl = useIntl()
  const {logout} = useAuth()
  useEffect(() => {
    logout()
  }, [])
  return (
    <>
      <Navbar />

      <PageBanner
        pageTitle={intl.formatMessage({ id: "SecureLogout" })}
        homePageText={intl.formatMessage({ id: "Homepage" })}
        homePageUrl="/"
        activePageText={intl.formatMessage({ id: "SecureLogout" })}
      />

      <section className="profile-authentication-area ptb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12">
              <LoginForm />
            </div>

           
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
