

import React, { useState } from "react";
import FsLightbox from "fslightbox-react";
import { Course } from '../../core/course/_models'
import { useIntl } from 'react-intl'

interface IProps {
  course: Course
}

const CoursesDetailsSidebar: React.FC<IProps> = ({ course }) => {
  const intl = useIntl()
  const [toggler, setToggler] = useState(false);

  return (
    <>
      <FsLightbox
        toggler={toggler}
        sources={["https://www.youtube.com/embed/bk7McNUjWgw"]}
      />

      <div className="courses-details-info">
        <div className="image">
          <img
            src="/img/courses/course1.jpg"
            alt="image"
            width={750}
            height={500}
          />

          <a href={"/event/" + course.id} className="link-btn"></a>

          <div className="content">
            <i className="flaticon-google-play"></i>
            <span>{intl.formatMessage({ id: "Start" })}</span>
          </div>
        </div>

        <ul className="info">
          <li className="price">
            <div className="d-flex justify-content-between align-items-center">
              <span>
                <i className="flaticon-price-tag"></i> {intl.formatMessage({ id: "CourseType" })}
              </span>
              {course.courseType?.name}
            </div>
          </li>
          <li>
            <div className="d-flex justify-content-between align-items-center">
              <span>
                <i className="flaticon-stopwatch"></i> {intl.formatMessage({ id: "Duration" })}
              </span>
              {course.duration}
            </div>
          </li>
          <li>
            <div className="d-flex justify-content-between align-items-center">
              <span>
                <i className="flaticon-distance-education"></i> {intl.formatMessage({ id: "NumberOfQuestion" })}
              </span>
              {course.courseSkillQuestions && course.courseSkillQuestions.length}
            </div>
          </li>
          <li>
            <div className="d-flex justify-content-between align-items-center">
              <span>
                <i className="flaticon-web"></i> {intl.formatMessage({ id: "TestType" })}
              </span>
              {course.proficiencyTest?.name}
            </div>
          </li>
          <li>
            <div className="d-flex justify-content-between align-items-center">
              <span>
                <i className="flaticon-web"></i> {intl.formatMessage({ id: "Level" })}
              </span>
              {course.courseLevel?.name}
            </div>
          </li>
          <li>
            <div className="d-flex justify-content-between align-items-center">
              <span>
                <i className="flaticon-web"></i> {intl.formatMessage({ id: "Branch" })}
              </span>
              {course.subinstitutionTypeBranch?.name}
            </div>
          </li>
          <li>
            <div className="d-flex justify-content-between align-items-center">
              <span>
                <i className="flaticon-web"></i> {intl.formatMessage({ id: "Lesson" })}
              </span>
              {course.lesson?.name}
            </div>
          </li>
          <li>
            <div className="d-flex justify-content-between align-items-center">
              <span>
                <i className="flaticon-open-padlock"></i> {intl.formatMessage({ id: "Access" })}
              </span>
              {
                course.publishingOptionId === 1 ? intl.formatMessage({ id: "OpraziOPE" }) :
                  course.publishingOptionId === 2 ? intl.formatMessage({ id: "Public" }) :
                    course.publishingOptionId === 3 ? intl.formatMessage({ id: "Institution" }) :
                      course.publishingOptionId === 4 ? intl.formatMessage({ id: "School" }) :
                        ''
              }
            </div>
          </li>
        </ul>

        <div className="btn-box">
          <a href={"/event/" + course.id} className="default-btn d-block w-100">
            <i className="flaticon-stopwatch"></i>
            {intl.formatMessage({ id: "Start" })} <span></span>
          </a>
        </div>

      </div>
    </>
  );
};

export default CoursesDetailsSidebar;
