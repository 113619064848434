import React from "react";
import Navbar from "../../components/Layouts/Navbar";
import PageBanner from "../../components/Common/PageBanner";
import StartProjectTwo from "../../components/Common/StartProjectTwo";
import OurHistoryContentOne from "../../components/History/OurHistoryContentOne";
import OurHistoryContentTwo from "../../components/History/OurHistoryContentTwo";
import Footer from "../../components/Layouts/Footer";
import HowItWork from "../../components/DataAnalyticsMLConsulting/HowItWork";
import { useIntl } from 'react-intl'

export default function Page() {
  const intl = useIntl()
  return (
    <>
      <Navbar />

      <PageBanner
        pageTitle={intl.formatMessage({ id: "Panel" })}
        homePageText={intl.formatMessage({ id: "Homepage" })}
        homePageUrl="/"
        activePageText={intl.formatMessage({ id: "Panel" })}
      />

      <HowItWork />

      <Footer />
    </>
  );
}
