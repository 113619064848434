export interface AuthModel {
  api_token: string
  refreshToken?: string
  currentRole?: string
  currentTerm?: string
  currentYear?: string
}

export interface UserModel {
  id: number
  username?: string
  email?: string
  first_Name?: string
  last_Name?: string
  fullName?: string
  roles?: Array<string>
  menus?: Array<string>
  claims?: Array<string>
  institutionId?: number
  subinstitutionId?: number
  customerId?: number
  employeeId?: number
  personId?: number
  sellerId?: number
  imageId?: number
  unitId?: number
}

export interface H5PUserModel {
  id: string,
  email: string,  
  username: string,
  role: string,
  csrfToken: string
}

export const initialH5PUser: H5PUserModel = {
  id: '',
  email: '',  
  username: '',
  role: '',
  csrfToken: ''
}

export interface ErrorResponse {
  type: string
  message: string
  stackTrace: string
}