

import React, { useState, useEffect } from "react";
import { useIntl } from 'react-intl'

interface CountdownProps {
  endDate: string; // Format: "Month Day, Year HH:mm:ss"
}

const Countdown: React.FC<CountdownProps> = ({ endDate }) => {
  const intl = useIntl()
  const calculateTimeLeft = () => {
    const endDateTime = new Date("August 23, 2025 17:00:00 PDT").getTime();
    const now = new Date().getTime();
    const timeRemaining = endDateTime - now;

    if (timeRemaining > 0) {
      const days = Math.floor(timeRemaining / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor(
        (timeRemaining % (1000 * 60 * 60)) / (1000 * 60)
      );
      const seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000);

      setCountdown({ days, hours, minutes, seconds });
    } else {
      setCountdown({ days: 0, hours: 0, minutes: 0, seconds: 0 });
    }
  };

  const [countdown, setCountdown] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      calculateTimeLeft();
    }, 1000);

    return () => clearInterval(interval);
  }, [endDate]);

  return (
    <>
      <div
        className="coming-soon-area"
        style={{ backgroundImage: `url(./img/coming-soon-bg.jpg)` }}
      >
        <div className="d-table">
          <div className="d-table-cell">
            <div className="coming-soon-content">
              <a href="/" className="logo">
                <img
                  src="/img/logo.png"
                  alt="image"
                  width={130}
                  height={54}
                />
              </a>
              <h2>{intl.formatMessage({ id: "CourseCompleted" })}</h2>

            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Countdown;
