
  
import React, { useState, FormEvent } from "react";
import { login, loginToH5P, getUserByToken } from "../../core/auth/_requests"
import { useRole, setRole } from '../../core/auth/RolesManager'
import { useAuth } from '../../core/auth/Auth'
import { setAuthH5P } from '../../core/auth/AuthH5P'
import { useNavigate } from "react-router-dom";
import { useIntl } from 'react-intl'

const LoginForm: React.FC = () => {
  const intl = useIntl()
  const H5P_CONFIG_KEY = process.env.REACT_APP_H5P_CONFIG_KEY || 'OpeOpraziH5PConfig'
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const { saveAuth, saveCurrentUser} = useAuth()
  const navigate = useNavigate();

  const handleSubmit = async (event:FormEvent) => {
    event.preventDefault();
    try {
      await login(email, password, "").then(async (auth) => {
        if (auth?.currentRole)
          setRole(auth?.currentRole)
        saveAuth(auth)

        await getUserByToken(auth?.api_token!, auth?.currentRole).then((user) => {
          saveCurrentUser(user)
        })

        await loginToH5P(auth?.api_token!, auth?.currentRole!).then(async (h5pUser) => {
          setAuthH5P(h5pUser)
        })

        navigate("/panel");
      })
    } catch (error) {
      console.error(error)
    }


  }

  return (
    <>
      <div className="login-form">
        <h2>{intl.formatMessage({ id: "UserLogin" })}</h2>

        <form onSubmit={(e) => handleSubmit(e)}>
          <div className="form-group">
            <label>{intl.formatMessage({ id: "Email" })}</label>
            <input
              type="text"
              className="form-control"
              placeholder={intl.formatMessage({ id: "Email" })}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>

          <div className="form-group">
            <label>{intl.formatMessage({ id: "Password" })}</label>
            <input
              type="password"
              className="form-control"
              placeholder={intl.formatMessage({ id: "Password" })}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>

          <div className="row align-items-center">
            <div className="col-lg-6 col-md-6 col-sm-6 remember-me-wrap">
              
            </div>

            <div className="col-lg-6 col-md-6 col-sm-6 lost-your-password-wrap">
              
            </div>
          </div>

          <button type="submit">{intl.formatMessage({ id: "Login" })}</button>
        </form>
      </div>
    </>
  );
};

export default LoginForm;
