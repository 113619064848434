
  
import React, {useEffect, useState} from "react";


import { getCourses } from '../../core/course/_requests'
import { Course } from '../../core/course/_models'
import { useAuth } from "../../core/auth/Auth"

const CoursesGridContent: React.FC = () => {
  const [courses, setCourses] = useState<Course[]>()
  const { currentUser } = useAuth()
  useEffect(() => {
    getCourses('').then((values) => { 
      setCourses(values.data)
  }) 
  }, [])
  return (
    <>
      <div className="courses-area ptb-10 bg-fafafb">
        <div className="container">
          <div className="row">
            {courses?.map((course, i) =>  
            <div className="col-lg-4 col-md-6" key={course.id}>
              <div className="single-courses-box">
                <div className="courses-image">
                  <a href={"/course/details/" + course.id} className="d-block image">
                    <img
                      src={"/img/courses/course" + (i % 6 + 1) + ".jpg"}
                      alt="image"
                      width={750}
                      height={500}
                    />
                  </a>

                  {/* Before favorite */}
                  <button type="button" className="fav">
                    <i className='bx bx-heart'></i>
                  </button>
                  {/* After favorite */}
                  {/* <button type="button" className="fav favorited">
                    <i class='bx bxs-heart' ></i>
                  </button> */}

                </div>
                <div className="courses-content">
                  <div className="course-author d-flex align-items-center">
                    <img
                      src="/img/user1.jpg"
                      className="rounded-circle"
                      alt="image"
                      width={300}
                      height={300}
                    />
                    <span>Oprazi OpE</span>
                  </div>
                  <h3>
                    <a href={"/course/details/" + course.id}>
                      {course.name}
                    </a>
                  </h3>
                  <p>
                      {course.description}
                  </p>
                  <ul className="courses-box-footer d-flex justify-content-between align-items-center">
                    <li>
                      <i className="flaticon-agendas"></i> {course.duration + ' Dakika'}
                    </li>
                    <li>
                      <a href={"/course/details/" + course.id} ><i className="flaticon-team"></i> Görüntüle</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            )}
          </div>
        </div>

        <div className="vector-shape6">
          <img src="/img/shape/vector-shape6.png" alt="image" width={533} height={413} />
        </div>
      </div>
    </>
  );
};

export default CoursesGridContent;
